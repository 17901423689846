type SrcEnabledElement = HTMLImageElement | HTMLIFrameElement;
type SrcObserver = null | IntersectionObserver;

/**
 * locally scoped variable registry
 */
const registry = {
  foreground_selector: "[loading=lazy][data-src]",
  background_selector: "[loading=lazy][data-bg-src]",

  src_observer: null as SrcObserver,

  lazy_load_event: new CustomEvent("lazy-loaded", {
    bubbles: true,
  }),
};

/**
 * sets the element's inline style background-image attribute to match
 * data-bg-src attribute and fires a bubbling 'lazy-loaded' event
 * @param $element the element to process
 */
const processBgSrcElement = ($element: SrcEnabledElement): void => {
  const in_memory_img = new Image();

  const load_listener = () => {
    $element.style.backgroundImage = `url("${in_memory_img.src}")`;
    delete $element.dataset.bgSrc;

    $element.dispatchEvent(registry.lazy_load_event);

    $element.removeEventListener("load", load_listener);
    $element.removeEventListener("error", load_listener);
    in_memory_img.remove();
  };

  in_memory_img.addEventListener("load", load_listener);
  in_memory_img.addEventListener("error", load_listener);
  in_memory_img.src = $element.dataset.bgSrc;
};

/**
 * sets the element src attribute to match data-src, then removes the data-src
 * attribute and fires a bubbling 'lazy-loaded' event
 * @param $element the element to process
 */
const processSrcElement = ($element: SrcEnabledElement): void => {
  const load_listener = () => {
    $element.dispatchEvent(registry.lazy_load_event);
    $element.removeEventListener("load", load_listener);
    $element.removeEventListener("error", load_listener);
  };

  $element.addEventListener("load", load_listener);
  $element.addEventListener("error", load_listener);

  $element.src = $element.dataset.src;
  delete $element.dataset.src;
};

/**
 * gets the src observer instance, creating it if it doesn't exist
 */
const getSrcObserver = (): IntersectionObserver => {
  registry.src_observer =
    registry.src_observer ||
    new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          processSrcElement(entry.target as SrcEnabledElement);
          observer.unobserve(entry.target);
        });
      },
      { rootMargin: "0px 0px -200px 0px" }
    );
  return registry.src_observer;
};

/**
 * lazy loads foreground elements with attributes loading=lazy and data-src
 */
const lazyLoadForegroundElements = () => {
  const elements: NodeListOf<HTMLElement> = document.querySelectorAll(
    registry.foreground_selector
  );

  elements.forEach(($element) => {
    getSrcObserver().observe($element);
  });
};

/**
 * natively lazy loads foreground elements with attributes loading=lazy and
 * data-src
 */
const lazyLoadForegroundElementsNative = () => {
  const elements: NodeListOf<HTMLElement> = document.querySelectorAll(
    registry.foreground_selector
  );

  elements.forEach(($element) => {
    processSrcElement($element as SrcEnabledElement);
  });
};

/**
 * lazy loads background images on elements with attributes loading=lazy and
 * data-bg-src
 */
const lazyLoadBackgroundElements = () => {
  const elements: NodeListOf<HTMLElement> = document.querySelectorAll(
    registry.background_selector
  );

  elements.forEach(($element) => {
    processBgSrcElement($element as SrcEnabledElement);
  });
};

/**
 * lazy loads foreground elements with data-src and loading=lazy attributes, and
 * background elements with data-bg-src and loading=lazy attributes.
 *
 * lazy-loaded elements will fire the 'lazy-loaded' event
 */
const register = (): void => {
  if ("loading" in HTMLImageElement.prototype) {
    lazyLoadForegroundElementsNative();
  } else {
    lazyLoadForegroundElements();
  }
  lazyLoadBackgroundElements();
};

export { register };
