/**
 * applies the class no-animation to the body tag
 * @param delay number of ms to wait
 */
const disableAllAnimations = (delay=0) => {
    setTimeout(() => {
        const $body = document.querySelector('body');
        $body.classList.add('no-animation');
    }, delay)
}
/**
 * removes the class no-animation from the body tag
 * @param delay number of ms to wait
 */
const enableAllAnimations = (delay=0) => {
    setTimeout(() => {
        const $body = document.querySelector('body');
        $body.classList.remove('no-animation');
    }, delay)
}

export {disableAllAnimations,enableAllAnimations}