import JSShare from "js-share";


/**
 * registers all .social_share buttons (the actual social media links) with
 * JSShare, causing them to launch the sharing component on click.
 * See: https://github.com/delfimov/JS-Share
 */
const registerSocialLinks = () => {
    let shareItems = document.querySelectorAll('.social_share');
    for (let i = 0; i < shareItems.length; i += 1) {
        shareItems[i].addEventListener('click', function share(e) {
            return JSShare.go(this);
        });
    }
}

/**
 * registers all [data-share-url] elements with click event listeners, so that
 * the JSShare.options.title and JSShare.options.url can be set on click. It
 * does this by reading the registered element's data-share-title and
 * data-share-url attributes.
 */
const registerShareLaunchers = () => {
    let share_launchers = document.querySelectorAll(`[data-share-url]`);
    share_launchers.forEach(launcher_element => {
        const $launcher_element = launcher_element as HTMLElement;
        $launcher_element.addEventListener('click', (event) => {
            JSShare.options.title = $launcher_element.dataset.shareTitle;
            JSShare.options.url = $launcher_element.dataset.shareUrl;
        });
    });
}

const register = () => {
    registerShareLaunchers();
    registerSocialLinks();
}

export {register}