/**
 * registers the GLightbox gallery behavior for the current page
 */

import GLightbox from 'glightbox';

declare global {
  interface Window {
    GLightbox: CallableFunction;
  }
}

const register = (): void => {
  window.GLightbox = GLightbox;
  window.GLightbox({
    touchNavigation: true,
    loop: true,
    autoplayVideos: true,
    svg: {
      next: '<i class="fa-solid fa-angle-right"></i>',
      prev: '<i class="fa-solid fa-angle-left"></i>',
      close: '<i class="fa-solid fa-times"></i>',
    }
  });
  const event = new CustomEvent('glightbox-loaded');
  document.dispatchEvent(event);
};

export { register };
