import { throttle } from "../lib/Utility";

/**
 * updates the --vh css variable
 */
const updateVH = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};

/**
 * creates a css variable, --vh, which you can use instead of vh in css. Unlink
 * the vanilla vh parameter, --vh will recalculate viewport height when the url
 * bar is visible on mobile
 */
const register = (): void => {
  updateVH();

  window.addEventListener(
    "resize",
    throttle(() => {
      // We execute the same script as before
      updateVH();
    })
  );
};

export { register, updateVH };
