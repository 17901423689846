// INCLUDES ********************************************************************
// *****************************************************************************

import "@local/sass/main.scss";

// import { domReady, windowReady } from "./lib/Utility";
import "jquery";
import bootstrap from "bootstrap/dist/js/bootstrap";

// import popper from "popper.js";
import "moment";
import * as PanelToggleInteractions from "@local/ts/interactions/panel-toggles";
import * as SocialShare from "@local/ts/interactions/SocialShareInteractions";
import * as GLightboxInteractions from "@local/ts/interactions/GLightboxInteractions";
import * as LazyLoadInteractions from "@local/ts/interactions/LazyLoadInteractions";
// import * as InputMasker from "@local/ts/interactions/InputMaskInteractions";
import * as MobileFriendlyVHInteractions from "@local/ts/interactions/MobileFriendlyVHInteractions";
import * as AnimationControl from "@local/ts/lib/animation-control";
import * as ImagingTools from "@local/ts/lib/imaging-tools";

// INIT ************************************************************************
// *****************************************************************************

window.bootstrap = bootstrap;

// ON PAGE LOAD ****************************************************************
// *****************************************************************************

window.addEventListener('DOMContentLoaded', (/*event*/) => {
  AnimationControl.enableAllAnimations(500);
  PanelToggleInteractions.registerAll();
  LazyLoadInteractions.register();
  MobileFriendlyVHInteractions.register();
  GLightboxInteractions.register();
  SocialShare.register();

  console.log('Prism Bundler JS Loaded!');
});
