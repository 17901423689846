/**
 * Handles toggling of elements
 */

/**
 * registers a click event handler to all elements that have the attribute
 * data-toggle-panel="selector". This handler will toggle the css class
 * 'visible' on the element matched by the selector.
 */
const registerAll = () => {
    const toggles = document.querySelectorAll(`[data-toggle-panel]`);
    toggles.forEach(($toggle)=> {
        const $el_toggle = $toggle as HTMLElement;
        const target_selector = $el_toggle.dataset.togglePanel
        const $target_panel = document.querySelector(target_selector);

        if ($target_panel) {
            const $body = document.querySelector('body') as HTMLElement;
            $el_toggle.addEventListener('click', (event) => {
                $target_panel.classList.toggle('visible');
                let active_panels = ($body.dataset.activePanels || "").split(' ');
                if ($target_panel.classList.contains('visible')) {
                    $el_toggle.dataset.dataToggled="true";
                    if (!active_panels.includes(target_selector)) {
                        active_panels.push(target_selector);
                    }
                } else {
                    $el_toggle.dataset.dataToggled="false";
                    if (active_panels.includes(target_selector)) {
                        active_panels.splice(active_panels.indexOf(target_selector), 1);
                    }
                }
                $body.dataset.activePanels = active_panels.join(' ').trim();
            });
        }
    });
}

export {registerAll}